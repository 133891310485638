import { VuexModule, Module, Action, Mutation, getModule } from 'vuex-module-decorators'
import ApplicationRecord from '@/models/ApplicationRecord'
import { UserManager } from 'oidc-client-ts'
import { getUserInfo } from '@/api/users'

import store from '@/store'
import router from '@/router'

export interface IUserState {
  name: string
  avatar: string
  introduction: string
  roles: string[]
  email: string
}

@Module({ dynamic: true, store, name: 'user' })
class User extends VuexModule implements IUserState {
  public name = ''
  public avatar = ''
  public introduction = ''
  public roles: string[] = []
  public email = ''
  public userManager = new UserManager({
    authority: process.env.VUE_APP_OPENID_SERVER_URL!,
    client_id: process.env.VUE_APP_CLIENT_ID!,
    redirect_uri: process.env.VUE_APP_URL!,
    silent_redirect_uri: `${process.env.VUE_APP_URL!}/dashboard`,
    post_logout_redirect_uri: "",
    response_type: 'code',
    scope: 'openid'
  })
  public user: any = null

  @Mutation
  private SET_USER(user: any) {
    this.user = user
  }

  @Mutation
  private SET_NAME(name: string) {
    this.name = name
  }

  @Mutation
  private SET_AVATAR(avatar: string) {
    this.avatar = avatar
  }

  @Mutation
  private SET_INTRODUCTION(introduction: string) {
    this.introduction = introduction
  }

  @Mutation
  private SET_ROLES(roles: string[]) {
    this.roles = roles
  }

  @Mutation
  private SET_EMAIL(email: string) {
    this.email = email
  }

  @Action
  public async SigninRedirect() {
    return this.userManager.signinRedirect()
  }

  @Action({ rawError: true})
  public async SigninRedirectCallback() {
    this.SET_USER(await this.userManager.signinRedirectCallback())
    ApplicationRecord.jwtStorage = false
    ApplicationRecord.jwt = this.user.access_token

    return this.user
  }

  @Action({ rawError: true})
  public async GetUserInfo() {
    if (this.user.access_token === '') {
      throw Error('GetUserInfo: token is undefined!')
    }
    const data: any = await getUserInfo()
    if (!data) {
      throw Error('Verification failed, please Login again.')
    }
    const roles = ['admin']
    // roles must be a non-empty array
    if (!roles || roles.length <= 0) {
      throw Error('GetUserInfo: roles must be a non-null array!')
    }

    this.SET_ROLES(roles)
    this.SET_NAME(data.firstname)
    // this.SET_AVATAR(avatar)
    // this.SET_INTRODUCTION(introduction)
    this.SET_EMAIL(data.email)
  }

  @Action
  public async GetUser() {
    this.SET_USER(await this.userManager.getUser())

    return this.user
  }

  @Action
  public async LogOut() {
    await this.userManager.revokeTokens()
    await this.userManager.removeUser()
    localStorage.removeItem('jwt')

    await router.push({ name: 'Login' })
  }
}

export const UserModule = getModule(User)
